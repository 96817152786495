<template>
  <div>
    <headerer></headerer>
    <div class="serve">
      <img
        src="https://oss.yedengtech.com/website-m/product/banner/画板 2.png"
        alt="topbg"
        class="topbg"
      />

      <p class="title">打造更多流量入口</p>

      <div class="product">
        <img
          class="img"
          src="https://oss.yedengtech.com/website-m/product/flowproduct2.png"
          alt=""
        />

        <div class="imginfo">
          <div>
            <p class="icontitle">快手小程序</p>
            <p class="icontitleafter">第三平台优质服务商</p>
            <ul class="imgul">
              <li>1.定向流量精准扶持-人群包</li>
              <li>2.快手专属定制化小程序</li>
              <li>3.流量触达方式多样化</li>
            </ul>
          </div>

          <div class="btn">
            <router-link :to="{ name: 'platformks' }">查看详情</router-link>
          </div>
        </div>

        <img
          class="img"
          src="https://oss.yedengtech.com/website-m/product/flowproduct1.png"
          alt=""
        />

        <div class="imginfo">
          <div>
            <p class="icontitle">微信小程序</p>
            <p class="icontitleafter">第三平台优质服务商</p>
            <ul class="imgul">
              <li>1.多种红包营销方式</li>
              <li>2.配套多种学习链路</li>
              <li>3.更懂微信私域生态</li>
            </ul>
          </div>

          <div class="btn">
            <router-link :to="{ name: 'platformwx' }">查看详情</router-link>
          </div>
        </div>
      </div>

      <div class="tagcontent">
        <p class="title2">功能大全</p>
        <div class="commoncontent">
          <div class="thefunctionsiconbox">
            <div class="secondtitle">课程营销管理</div>
            <div class="icons">
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1886.png" alt="icon" />
                <p class="imgp">红包</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1887.png" alt="icon" />
                <p class="imgp">折扣</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1888.png" alt="icon" />
                <p class="imgp">套餐</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1889.png" alt="icon" />
                <p class="imgp">留资</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1890.png" alt="icon" />
                <p class="imgp">发货</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1891.png" alt="icon" />
                <p class="imgp">试听课</p>
              </div>
            </div>
          </div>

          <div class="thefunctionsiconbox">
            <div class="secondtitle">课程类型</div>
            <div class="icons">
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1892.png" alt="icon" />
                <p class="imgp">直播课程</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1893.png" alt="icon" />
                <p class="imgp">外语评测</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1894.png" alt="icon" />
                <p class="imgp">问答课程</p>
              </div>
              <!-- <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1895.png" alt="icon" />
                <p class="imgp">图片课程</p>
              </div> -->
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1896.png" alt="icon" />
                <p class="imgp">图文课程</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1897.png" alt="icon" />
                <p class="imgp">音频课程</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1898.png" alt="icon" />
                <p class="imgp">视频课程</p>
              </div>
            </div>
          </div>

          <div class="thefunctionsiconbox">
            <div class="secondtitle">特色功能</div>
            <div class="icons">
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1899.png" alt="icon" />
                <p class="imgp">打卡</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1900.png" alt="icon" />
                <p class="imgp">优惠券</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1901.png" alt="icon" />
                <p class="imgp">抖音群聊</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1902.png" alt="icon" />
                <p class="imgp">驾考模拟训练</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1903.png" alt="icon" />
                <p class="imgp">AI英语跟读</p>
              </div>
            </div>
          </div>

          <div class="thefunctionsiconbox">
            <div class="secondtitle">付费数据分析</div>
            <div class="icons">
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1904.png" alt="icon" />
                <p class="imgp">支付渠道打通</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1905.png" alt="icon" />
                <p class="imgp">广告投放分析</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1906.png" alt="icon" />
                <p class="imgp">支付时段分析</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1907.png" alt="icon" />
                <p class="imgp">终端设备适配</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1908.png" alt="icon" />
                <p class="imgp">用户地域分析</p>
              </div>
            </div>
          </div>

          <div class="thefunctionsiconbox">
            <div class="secondtitle">资金管理</div>
            <div class="icons">
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1909.png" alt="icon" />
                <p class="imgp">资金收益</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1910.png" alt="icon" />
                <p class="imgp">订单管理</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1911.png" alt="icon" />
                <p class="imgp">独立资金账户</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1912.png" alt="icon" />
                <p class="imgp">多渠道收款</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1913.png" alt="icon" />
                <p class="imgp">分销管理</p>
              </div>
            </div>
          </div>

          <div class="thefunctionsiconbox">
            <div class="secondtitle">私人定制</div>
            <div class="icons">
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1914.png" alt="icon" />
                <p class="imgp">短信模版</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1915.png" alt="icon" />
                <p class="imgp">直播物料</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1916.png" alt="icon" />
                <p class="imgp">课程物料</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1917.png" alt="icon" />
                <p class="imgp">独立小程序</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1918.png" alt="icon" />
                <p class="imgp">后台管理</p>
              </div>
              <div class="icon">
                <img src="https://oss.yedengtech.com/website-m/product/icons/组 1919.png" alt="icon" />
                <p class="imgp">位置展示</p>
              </div>
            </div>
          </div>

          <div class="btnn" style="left: 0">
            <router-link :to="{ name: 'platformbkserve' }"
              >查看后台专属玩法</router-link
            >
          </div>
        </div>
      </div>

      <div class="procedure">
        <p class="title2">专属小程序</p>

        <img src="https://oss.yedengtech.com/website-m/product/exclusiveimg1.png" alt="" />

        <p class="common">品牌专属内容阵地，多种店铺装修组件，定制设计风格</p>
        <p class="commonafter">
          专业设计团队为您倾心打造，根据课程风格量身定制抖音小程序，轻松拥有独属的课程设计，在起跑线上助力您的商业变现之路。
        </p>

        <img
          style="margin-top: 30px"
          src="https://oss.yedengtech.com/website-m/product/exclusiveimg2.png"
          alt=""
        />

        <p class="common">海量课程支持，一键上架至专属小程序，快速裂变分销</p>
        <p class="commonafter">
          夜灯课堂涵盖多领域优质内容，提供⾼分成分销课程。⼀键上架，快速进⾏知识变现，多元化快速匹配适学用户，解决内容问题。
        </p>

        <div class="btnn">
          <router-link :to="{ name: 'platformkgstore' }"
            >查看更多业务</router-link
          >
        </div>
      </div>
    </div>
    <footerer></footerer>
  </div>
</template>

<script>
import headerer from "../headerer.vue";
import footerer from "../footerer.vue";
export default {
  components: {
    headerer,
    footerer,
  },
};
</script>

<style lang="less" scoped>
.serve {
  width: 100vw;
  min-height: 100vh;
  animation: renderpage 1s cubic-bezier(0, 0.68, 1, 0.64) forwards;

  .product {
    width: 100%;
    padding: 10px;
    .img {
      width: 100%;
      object-fit: contain;
      border-radius: 12px;
    }
    .icontitle {
      font-size: 17px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 600;
      color: #ff8f29;
      margin-bottom: 10px;
    }
    .icontitleafter {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #010000;
    }
    .imginfo {
      display: flex;
      justify-content: space-evenly;
    }
    .imgul {
      margin-top: 5px;
      li {
        list-style-type: none;
        font-size: 12px;
        
      }
    }
    .btn {
      height: 25px;
      width: 80px;
      background: #ff8f29;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #fff;
    }
  }

  .commoncontent {
    padding: 0px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .thefunctionsiconbox {
      width: 100%;
      margin-bottom: 30px;
      .icons {
        display: flex;
        width: 100%;
        justify-content: space-between;
        position: relative;
        .icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          transition: all 0.1s linear;
          img {
            width: calc(100vw * 0.1);
            object-fit: contain;
            width: 36px;
            height: 36px;
            border-radius: 12px;
          }
          .imgp {
            width: 36px;
            font-size: 10px;
            transform: scale(0.9);
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #010000;
            margin-top: 10px;
            transition: all 0.1s linear;
            text-align: center;
          }
        }
      }
    }
  }

  .procedure {
    width: 100%;
    padding: 10px;
    img {
      width: 100%;
      object-fit: contain;
      border-radius: 18px;
    }
  }
}

.topbg {
  width: 100%;
  object-fit: cover;
}

.title {
  font-weight: 600;
  margin: 20px 0;
  font-size: 16px;
  color: #000;
  text-align: center;
}

.title2 {
  font-weight: 600;
  font-size: 14px;
  margin: 20px 0;
  text-align: center;
}
.secondtitle {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 12px;
  text-align: center;
}

.common {
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
}
.commonafter {
  color: rgba(33, 36, 41, 0.5);
  font-size: 12px;
  padding: 10px;
  padding-top: 0;
}
.btnn {
  width: 160px;
  height: 40px;
  background: #ff8f29;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  position: relative;
  left: calc(calc(100vw - 180px) / 2);
}

@keyframes renderpage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
